import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import Tagger from '@elbwalker/tagger';
import { Theme } from '@sumup/design-tokens';

import {
  Alignment,
  ImagePresetSize,
  SplitLayoutVerticalImageAlignment,
} from '../../interfaces';
import { getAlignButtonId } from '../../ColumnLayoutService';

import { RichtextColumnProps } from './types';

import dataSelector from '~/shared/util/data-selector';
import RichText from '~/shared/components/RichText';
import * as columnRichtext from '~/shared/components/RichText/configs/column-richtext';
import { ALIGNMENT, PRESET_IMAGE_SIZE } from '~/shared/constants';

const wrapperStyles = ({
  theme,
  imagePresetSize,
  desktopAlignment,
  tabletAlignment,
  mobileAlignment,
  splitLayout,
  alignAllButtons,
  splitLayoutVerticalImageAlignment,
}: {
  theme?: Theme;
  imagePresetSize?: ImagePresetSize;
  desktopAlignment?: Alignment;
  tabletAlignment?: Alignment;
  mobileAlignment?: Alignment;
  splitLayout?: boolean;
  alignAllButtons?: boolean;
  splitLayoutVerticalImageAlignment?: SplitLayoutVerticalImageAlignment;
}): SerializedStyles => css`
  ${splitLayout &&
  css`
    width: ${imagePresetSize === PRESET_IMAGE_SIZE.ICON
      ? `calc(100% - ${theme.spacings.zetta} - ${theme.spacings.giga})`
      : '50%'};
  `}
  ${theme.mq.untilKilo} {
    text-align: ${mobileAlignment === ALIGNMENT.CENTER ? 'center' : 'left'};
  }

  ${theme.mq.kilo} {
    text-align: ${tabletAlignment === ALIGNMENT.CENTER ? 'center' : 'left'};
    ${alignAllButtons &&
    css`
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
    `}
  }

  ${theme.mq.mega} {
    text-align: ${desktopAlignment === ALIGNMENT.CENTER ? 'center' : 'left'};
  }

  ${splitLayout &&
  splitLayoutVerticalImageAlignment === ALIGNMENT.MIDDLE &&
  css`
    align-self: center;
  `}
`;

const Wrapper = styled('div')(wrapperStyles);

/**
 * Richtext Column to display in column layout
 */
export const RichtextColumn: FC<RichtextColumnProps> = ({
  content = {},
  index,
  splitLayout,
  splitLayoutVerticalImageAlignment,
  alignments,
  trackingContentEntry = {},
  soloDesign = {},
  imagePreset = {},
  numberOfColumns,
  taggerInfo,
  alignAllButtons,
}) => {
  const tagger = React.useMemo(() => {
    if (!taggerInfo) {
      return {};
    }
    const { action, entity, trigger, name } = taggerInfo;
    const t = Tagger();

    const info = {
      ...t.entity(entity),
      ...t.action(trigger, action),
    };

    if (name) {
      return {
        ...info,
        ...t.property(entity, `${entity}_name`, name),
      };
    }

    return info;
  }, [taggerInfo]);

  if (isEmpty(content)) {
    return null;
  }

  const { imagePresetSize } = imagePreset;
  const alignButtonId = alignAllButtons && getAlignButtonId(content);

  return (
    <Wrapper
      data-selector={dataSelector(`column-${index + 1}`, 'columnLayout')}
      data-elbcontext="component:richtext_column"
      splitLayout={splitLayout}
      imagePresetSize={imagePresetSize}
      alignAllButtons={alignAllButtons}
      splitLayoutVerticalImageAlignment={splitLayoutVerticalImageAlignment}
      {...tagger}
      {...alignments}
    >
      <RichText
        richText={content}
        renderNode={columnRichtext.renderNode({
          trackingContentEntry,
          soloDesign,
          imagePreset,
          numberOfColumns,
          alignButtonId,
        })}
        renderMark={columnRichtext.renderMark}
      />
    </Wrapper>
  );
};
